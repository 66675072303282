<template>
  <a-row :gutter="15" class="start-invite">
    <a-col :lg="8" :xs="24">
      <sdPageHeader :title="i18n.t('start.promotions')" class="invite__title" />
    </a-col>
    <a-col :lg="16" v-if="isPc">
      <sdPageHeader :title="i18n.t('start.referralProgram')" class="invite__title" />
    </a-col>
    <a-col :lg="8" :xs="24">
      <a-carousel :autoplay="true">
        <CardWrapper v-for="(banner, index) of banners" :key="index">
          <img :src="require(`@/static/img/banner/slider-${banner.img}`)" alt="" />
          <figcaption>
            <h2>{{ banner.title }}</h2>
            <p v-html="banner.text"></p>
          </figcaption>
        </CardWrapper>
      </a-carousel>
    </a-col>
    <a-col :xs="24" v-if="!isPc">
      <sdPageHeader :title="i18n.t('start.referralProgram')" class="invite__title" />
    </a-col>
    <a-col :lg="16" :xs="24">
      <sdCards headless class="start__referral-card">
        <div class="start__referral-card-wrapper">
          <div>
            <h2 class="invite__title">
              {{ i18n.t('start.partnerProgramm.title') }}
            </h2>
            <p>
              {{ i18n.t('start.partnerProgramm.description') }}
            </p>
            <router-link to="/partners">
              {{ i18n.t('start.partnerProgramm.more') }}
            </router-link>
          </div>
          <div class="card__wrapper">
            <h3 @click="copyAddress">
              {{ `${refferalLink[i18n.locale]}?ref=${profileState?.referral_program?.code}` }}
            </h3>
            <sdButton
              size="default"
              class="start__copy copy"
              :outlined="true"
              type="light"
              @click="copyAddress"
              v-if="isPc"
            >
              <sdFeatherIcons type="copy" />
            </sdButton>
          </div>
        </div>
      </sdCards>
    </a-col>
  </a-row>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref } from '@vue/runtime-core';
import { message } from 'ant-design-vue';
import { CardWrapper } from '../style';
const StartHeader = {
  name: 'StartHeader',
  components: {
    CardWrapper,
  },
  setup() {
    const { state } = useStore();
    const i18n = reactive(useI18n());

    const profileState = computed(() => state.profile.data);
    const isPc = computed(() => window.innerWidth > 610);

    const refferalLink = ref({
      en: process.env.VUE_APP_API_EN_REFERAL_SHARING_URL,
      ru: process.env.VUE_APP_API_RU_REFERAL_SHARING_URL,
    });

    const copyAddress = () => {
      window.navigator.clipboard.writeText(
        `${refferalLink.value[i18n.locale]}?ref=${profileState.value?.referral_program.code}`,
      );
      message.success(i18n.t('messages.linkCopied'));
    };
    const copyCode = () => {
      window.navigator.clipboard.writeText(profileState.value?.referral_program.code);
      message.success(i18n.t('messages.codeCopied'));
    };
    onMounted(() => {});
    const banners = computed(() => [
      {
        title: i18n.t('start.partnerProgramm.bannerTitle'),
        text: i18n.t('start.partnerProgramm.banner'),
        img: '1.jpg',
      },
    ]);

    return {
      i18n,
      profileState,
      isPc,
      refferalLink,
      banners,
      copyAddress,
      copyCode,
    };
  },
};
export default StartHeader;
</script>

<style lang="scss" scoped>
.start {
  &__hello {
    font-size: 24px;
    font-weight: 600;
    margin: 12px 0;
    & > span {
      font-size: 16px;
      color: #2c9aea;
      cursor: pointer;
    }
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  .start__text {
    margin-left: 20px;
  }
  .ant-btn-light {
    margin-left: 20px;
  }
  &:not(:nth-last-child(1)) {
    margin-right: 20px;
  }
  h2 {
    margin-bottom: 4px;
  }
  p,
  h3 {
    margin-bottom: 0;
  }
}

.card__wrapper {
  justify-content: flex-start !important;
  h3 {
    margin-right: 20px;
  }
}
</style>
